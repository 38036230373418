import { memo } from 'react'
import type { FC } from 'react'
import { Placeholder } from './styles'
import { ICONS } from './constants'
import { Props } from './types'

const Icon: FC<Props> = ({ name, size = 16, onClick, ...rest }) => {
  const SelectedIcon = ICONS[name]

  if (!SelectedIcon) return <Placeholder>?</Placeholder>

  return <SelectedIcon onClick={onClick} size={size} {...rest} />
}

export default memo(Icon)
