import { makeVar } from '@apollo/client'
import { Props as SnackbarProps } from 'components/Snackbar/types'

const emptySnackBar: SnackbarProps = {
  isOpen: false,
  title: '',
}

export const snackbarVar = makeVar<any>(emptySnackBar)

export const resetSnackbarVar = () => {
  snackbarVar(emptySnackBar)
}

export const setSnackbarVar = (data: Partial<any>) => {
  snackbarVar({ ...emptySnackBar, ...data })
}

export const updateSnackbarVar = (data: Partial<any>) => {
  snackbarVar({ ...snackbarVar(), ...data })
}
