import { createTheme } from '@material-ui/core/styles'
import breakpoints from './breakpoints'
import shadows from './shadows'
import typography from './typography'
import { lightVariant } from './variants'
import props from './props'

const materialUITheme = createTheme(
  {
    spacing: 4,
    breakpoints,
    typography,
    props,
    shadows,
    palette: lightVariant.palette,
  },
  {
    name: lightVariant.name,
    body: lightVariant.body,
    header: lightVariant.header,
    sidebar: lightVariant.sidebar,
  },
)

export default materialUITheme
