import { FC, memo } from 'react'
import type { Props } from '../types'

const OpenUrl: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99939 1C6.61492 1 5.26154 1.41054 4.1104 2.17971C2.95925 2.94888 2.06205 4.04213 1.53223 5.32122C1.19643 6.13192 1.01777 6.9942 1.00073 7.86274H1.82434C1.83636 7.30462 1.92425 6.75051 2.08576 6.21569H4.98939C4.89898 6.76114 4.85031 7.31174 4.84338 7.86274H5.66699C5.67447 7.31142 5.727 6.76055 5.82458 6.21569H10.1742C10.3856 7.39584 10.3856 8.60416 10.1742 9.78431H6.90162V10.6078H9.98958C9.62311 11.9619 8.9739 13.1841 8.08998 14.136C8.07863 14.1488 8.06468 14.159 8.04906 14.166C8.03344 14.173 8.0165 14.1766 7.99939 14.1765C7.98227 14.1766 7.96534 14.173 7.94972 14.166C7.93409 14.159 7.92014 14.1488 7.9088 14.136C7.52831 13.7262 7.1913 13.2664 6.90162 12.7675V14.9134C8.17576 15.1157 9.48301 14.9622 10.6782 14.4672C11.9573 13.9373 13.0505 13.0401 13.8197 11.889C14.5888 10.7378 14.9994 9.38447 14.9994 8C14.9972 6.14415 14.259 4.36494 12.9467 3.05266C11.6344 1.74038 9.85523 1.00218 7.99939 1ZM13.5973 5.39216H10.8399C10.5398 4.14752 9.99252 2.97593 9.23056 1.94706C10.1765 2.14145 11.0639 2.55416 11.8221 3.1523C12.5802 3.75043 13.1881 4.51744 13.5973 5.39216ZM14.1759 8C14.1763 8.60445 14.0878 9.20567 13.913 9.78431H11.0094C11.2052 8.60283 11.2052 7.39717 11.0094 6.21569H13.913C14.0878 6.79433 14.1763 7.39555 14.1759 8ZM7.99939 1.82353C8.0165 1.82343 8.03344 1.827 8.04906 1.83398C8.06468 1.84096 8.07863 1.85121 8.08998 1.86402C8.9739 2.81588 9.62311 4.03814 9.98958 5.39216H6.00919C6.37566 4.03814 7.02488 2.81588 7.9088 1.86402C7.92014 1.85121 7.93409 1.84096 7.94972 1.83398C7.96534 1.827 7.98227 1.82343 7.99939 1.82353ZM6.76821 1.94706C6.00626 2.97593 5.45897 4.14752 5.1589 5.39216H2.40145C2.81064 4.51744 3.41855 3.75043 4.17671 3.1523C4.93486 2.55416 5.82228 2.14145 6.76821 1.94706ZM9.23056 14.0529C9.99252 13.0241 10.5398 11.8525 10.8399 10.6078H13.5973C13.1881 11.4826 12.5802 12.2496 11.8221 12.8477C11.0639 13.4458 10.1765 13.8586 9.23056 14.0529Z"
      fill="currentColor"
    />
    <path
      d="M1.88965 10.1424C1.64708 10.1424 1.45044 10.339 1.45044 10.5816C1.45044 10.8241 1.64708 11.0208 1.88965 11.0208H3.465L1.22977 13.1739C1.05506 13.3421 1.04986 13.6202 1.21814 13.7949C1.38642 13.9696 1.66447 13.9748 1.83918 13.8065L4.11486 11.6145V13.2457C4.11486 13.4883 4.31151 13.685 4.55408 13.685C4.79665 13.685 4.99329 13.4883 4.99329 13.2457V10.5816C4.99329 10.339 4.79665 10.1424 4.55408 10.1424H1.88965Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(OpenUrl)
