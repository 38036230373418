import { useCallback } from 'react'
import { snackbarVar, setSnackbarVar } from 'apollo/reactive/snackbar'
import { Props as SnackbarProps } from 'components/Snackbar/types'
import { useReactiveVar } from '@apollo/client'

const useSnackbar = () => {
  const snackbarProps = useReactiveVar(snackbarVar)

  const closeSnackbar = useCallback((data: SnackbarProps) => {
    setSnackbarVar({ ...data, isOpen: false })
  }, [])

  const openSnackbar = useCallback(
    (data: SnackbarProps) => {
      setSnackbarVar({
        isOpen: true,
        onClose: () => closeSnackbar(data),
        ...data,
      })
    },
    [closeSnackbar],
  )

  return {
    snackbarProps,
    openSnackbar,
  }
}

export default useSnackbar
